import { Helmet } from "react-helmet";

function Seo() {
  return (
    <Helmet>
      <link rel="icon" type="image/webp" href="/images/browser_logo.webp" />
      <title>
        Balıkesir Ağız Diş Ve Çene Cerrahisi - Uzm. Dt. Gül Fikirli Kaçmaz
      </title>
      <meta
        name="description"
        content="Hekimlerimiz, Balıkesir'de başta ÇENE CERRAHİSİ, İMPLANT (VİDALI DİŞ), ÇENE EKLEMİ HASTALIKLARI ve TEDAVİSİ, DİŞ ETİ TEDAVİSİ (PERİODONTOLOJİ), DİŞ BEYAZLATMA (BLEACHING), ESTETİK DİŞ HEKİMLİĞİ, DİŞ ÇÜRÜĞÜ TEDAVİSİ (RESTORATİF DİŞ TEDAVİSİ), KANAL TEDAVİSİ (ENDODONTİ), DİŞ PROTEZLERİ, ÇOCUK DİŞ HEKİMLİĞİ (PEDODONTİ), DİŞ TELİ TEDAVİSİ (ORTODONTİ) tedavileri olmak üzere bütün alanlarda deneyimleri ile hizmet vermektedirler."
      />
      <meta
        name="keywords"
        content="Balıkesir ÇENE CERRAHİSİ, Balıkesir İMPLANT (VİDALI DİŞ), Balıkesir ÇENE EKLEMİ HASTALIKLARI ve TEDAVİSİ, Balıkesir DİŞ ETİ TEDAVİSİ (PERİODONTOLOJİ), Balıkesir DİŞ BEYAZLATMA (BLEACHING), Balıkesir ESTETİK DİŞ HEKİMLİĞİ, Balıkesir DİŞ ÇÜRÜĞÜ TEDAVİSİ (RESTORATİF DİŞ TEDAVİSİ), Balıkesir KANAL TEDAVİSİ (ENDODONTİ), Balıkesir DİŞ PROTEZLERİ, Balıkesir ÇOCUK DİŞ HEKİMLİĞİ (PEDODONTİ), Balıkesir DİŞ TELİ TEDAVİSİ (ORTODONTİ), Balıkesir 20 YAŞ DİŞLERİ, Balıkesir GÖMÜLÜ/GÖMÜK DİŞLER, Balıkesir DİŞ ÇEKİMİ, Balıkesir DİŞ APSESİ/ DİŞ İLTİHABI TEDAVİSİ, Balıkesir ÇENE KİSTLERİ ve TÜMÖRLERİ, Balıkesir KÖK UCU REZEKSİYONU (APİKAL REZEKSİYON), Balıkesir YUMUŞAK DOKU CERRAHİSİ (VESTİBÜL0PLASTİ), Balıkesir KEMİK DÜZELTME (ALVEOLOPLASTİ), Balıkesir SİNÜS LİFTİNG (SİNÜS YÜKSELTME/KALDIRMA), Balıkesir KEMİK GREFTİ (KEMİK TOZU), Balıkesir ARTROSENTEZ (EKLEM YIKAMA/ EKLEM ENJEKSİYONU), Balıkesir BRUKSİZM (DİŞ SIKMA/GICIRDATMA), Balıkesir SPLİNT/ GECE PLAĞI, Balıkesir MASSETER BOTOKS, Balıkesir DİŞ TAŞI (TARTAR) TEMİZLİĞİ, Balıkesir DİŞ ETİ ÇEKİLMESİ (DİŞ ETİ AMELİYATI), Balıkesir FRENEKTOMİ (DUDAK/DİL BAĞI ALINMASI), Balıkesir DİŞ ETİ ESTETİĞİ (GİNGİVEKTOMİ/GİNGİVOPLASTİ), Balıkesir GÜLÜŞ TASARIMI, Balıkesir PORSELEN DİŞLER, Balıkesir ZİRKONYUM DİŞLER, Balıkesir LAMİNA (YAPRAK PORSELEN), Balıkesir ESTETİK DOLGULAR, Balıkesir DİŞ DOLGUSU (KOMPOZİT DOLGU), Balıkesir TOTAL (TAM DAMAK) PROTEZLER, Balıkesir PARSİYEL (YARIM DAMAK) / KANCALI PROTEZLER, Balıkesir HASSAS BAĞLANTILI/ ÇITÇITLI PROTEZLER, Balıkesir SABİT PROTEZLER (DİŞ KAPLAMA/ DİŞ KÖPRÜSÜ), Balıkesir İMPLANT ÜSTÜ SABİT PROTEZLER, Balıkesir İMPLANT ÜSTÜ HAREKETLİ/ ÇIKARILABİLİR PROTEZLER"
      />
      <meta
        name="author"
        content="Balıkesir Ağız Diş Ve Çene Cerrahisi - Uzm. Dt. Gül Fikirli Kaçmaz"
      />
      <meta
        property="og:site_name"
        content="Balıkesir Ağız Diş Ve Çene Cerrahisi - Uzm. Dt. Gül Fikirli Kaçmaz"
      />
      <meta
        property="og:title"
        content="Balıkesir Ağız Diş Ve Çene Cerrahisi - Uzm. Dt. Gül Fikirli Kaçmaz"
      />
      <meta
        name="og:description"
        content="Hekimlerimiz, Balıkesir'de başta ÇENE CERRAHİSİ, İMPLANT (VİDALI DİŞ), ÇENE EKLEMİ HASTALIKLARI ve TEDAVİSİ, DİŞ ETİ TEDAVİSİ (PERİODONTOLOJİ), DİŞ BEYAZLATMA (BLEACHING), ESTETİK DİŞ HEKİMLİĞİ, DİŞ ÇÜRÜĞÜ TEDAVİSİ (RESTORATİF DİŞ TEDAVİSİ), KANAL TEDAVİSİ (ENDODONTİ), DİŞ PROTEZLERİ, ÇOCUK DİŞ HEKİMLİĞİ (PEDODONTİ), DİŞ TELİ TEDAVİSİ (ORTODONTİ) tedavileri olmak üzere bütün alanlarda deneyimleri ile hizmet vermektedirler."
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="tr_TR" />
      <meta property="og:image" content="/images/browser_logo.webp" />
      <meta property="og:url" content="https://www.gulfikirlikacmaz.com/" />
    </Helmet>
  );
}

export default Seo;
