import React, { useEffect, useState, lazy, Suspense } from "react";
import { FaCircleArrowUp } from "react-icons/fa6";
import styles from "./styles/App.module.css";
import Seo from "./components/Seo";

const Header = lazy(() => import("./components/header/Header"));
const SectionLayout = lazy(() => import("./components/SectionLayout"));
const Slider = lazy(() => import("./components/Slider"));
const Resume = lazy(() => import("./components/resume/Resume"));
const Treatment = lazy(() => import("./components/treatment/Treatment"));
const Contact = lazy(() => import("./components/contact/Contact"));
const Gallery = lazy(() => import("./components/Gallery"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      setShowScrollButton(scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    window.history.scrollRestoration = "manual";

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <Seo />
      <Suspense>
        <Header />
      </Suspense>
      <Suspense>
        <SectionLayout>
          <Suspense>
            <Slider />
          </Suspense>
          <Suspense>
            <Resume />
          </Suspense>
          <Suspense>
            <Treatment />
          </Suspense>
          <Suspense>
            <Gallery />
          </Suspense>
          <Suspense>
            <Contact />
          </Suspense>
        </SectionLayout>
      </Suspense>
      <Suspense>
        <Footer />
      </Suspense>
      {showScrollButton && (
        <button onClick={scrollToTop} className={styles.scroll_button}>
          <FaCircleArrowUp className={styles.scroll_icon} />
        </button>
      )}
    </React.Fragment>
  );
}

export default App;
